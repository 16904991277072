import { Flex, FlexProps } from '@chakra-ui/react';

function Paper(props: FlexProps) {
  return (
    <Flex
      bg={`url('/white_wall_hash.webp')`}
      bgSize='280px'
      boxShadow='6px 6px 8px -4px rgba(0,0,0,0.61)'
      color='black'
      overflow='hidden'
      transition='all 220ms cubic-bezier(0.390, 0.575, 0.565, 1.000)'
      {...props}
    >
      {props.children}
    </Flex>
  );
}

export default Paper;
