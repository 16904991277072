import { ButtonGroup } from '@chakra-ui/react';

import PayOrDonate from './join/Donate';
import JoinNow from './join/JoinNow';

const CustomerButtons = () => {
  return (
    <ButtonGroup size={{ base: 'sm', md: 'md' }} spacing={1}>
      <JoinNow borderBottomRadius={0} borderTopRightRadius={0} />
      <PayOrDonate borderBottomRadius={0} borderTopLeftRadius={0} />
    </ButtonGroup>
  );
};

export default CustomerButtons;
