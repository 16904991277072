import { useCreateCheckoutSessionMutation } from '@/reducers/api';
import { useAppDispatch, useAppSelector } from '@/reducers/hooks';
import { closeMenu, openMenu } from '@/reducers/menu/menuSlice';

import {
  Box,
  Button,
  ButtonGroup,
  ButtonProps,
  FormControl,
  FormLabel,
  Heading,
  Input,
  InputGroup,
  InputLeftElement,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalOverlay,
  Text,
  Textarea,
} from '@chakra-ui/react';
import { yupResolver } from '@hookform/resolvers/yup';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import getStripe from 'shared/lib/stripe';
import * as yup from 'yup';

const schema = yup
  .object({
    name: yup.string().required('Please enter your name'),
    email: yup.string().email('Please enter a valid email').required('Please enter your email'),
    notes: yup.string().notRequired(),
    amount: yup.number().positive().integer().required('Please enter an amount'),
  })
  .required();

type FormInputs = yup.InferType<typeof schema>;

function PayHere(props: ButtonProps) {
  const [errorMessage, setErrorMessage] = useState<string | undefined>(undefined);
  const [createCheckout, { isError, isLoading }] = useCreateCheckoutSessionMutation();
  const menuState = useAppSelector((state) => state.menu.isOpen);
  const isOpen = menuState === 'payment';
  const dispatch = useAppDispatch();
  const onClose = () => dispatch(closeMenu());
  const onOpen = () => dispatch(openMenu('payment'));

  const {
    register,
    handleSubmit,
    formState: { isSubmitting },
  } = useForm<FormInputs>({
    resolver: yupResolver(schema),
  });

  const onSubmit = async (data: FormInputs) => {
    try {
      // Create a Checkout Session.
      const { id: checkoutSessionId } = await createCheckout({
        customerName: data.name,
        customerEmail: data.email,
        customerNotes: data.notes ?? '',
        amount: data.amount,
      }).unwrap();

      // Redirect to Checkout.
      const stripe = await getStripe();
      if (!stripe) {
        return;
      } else {
        const { error: stripeError } = await stripe.redirectToCheckout({
          // Make the id field from the Checkout Session creation API response
          // available to this file, so you can provide it as parameter here
          // instead of the {{CHECKOUT_SESSION_ID}} placeholder.
          sessionId: checkoutSessionId,
        });
        // If `redirectToCheckout` fails due to a browser or network
        // error, display the localized error message to your customer
        // using `error.message`.
        setErrorMessage(stripeError?.message);
      }
    } catch (err) {
      if (err instanceof Error) {
        setErrorMessage(err.message);
      }
    }
  };

  return (
    <>
      <Button colorScheme='yellow' onClick={onOpen} {...props}>
        Pay/Donate
      </Button>
      <Modal isOpen={isOpen} size='md' onClose={onClose}>
        <ModalOverlay />
        <ModalContent bg={`url('/white_wall_hash.webp')`} borderRadius={0} color='black'>
          <ModalCloseButton />
          <form onSubmit={handleSubmit(onSubmit)}>
            <ModalBody>
              <FormControl mb={4} mt={4}>
                <FormLabel>Your Name</FormLabel>
                <Input {...register('name')} variant='filled' />
              </FormControl>
              <FormControl mb={4}>
                <FormLabel>Your Email</FormLabel>
                <Input type='email' {...register('email')} variant='filled' />
              </FormControl>
              <FormControl mb={4}>
                <FormLabel>Amount</FormLabel>
                <InputGroup>
                  <InputLeftElement color='gray.300' fontSize='1.2em' pointerEvents='none'>
                    $
                  </InputLeftElement>
                  <Input
                    _placeholder={{
                      color: 'gray.300',
                    }}
                    placeholder='Enter amount'
                    type='number'
                    variant='filled'
                    {...register('amount')}
                  />
                </InputGroup>
              </FormControl>
              <FormControl mb={4}>
                <FormLabel>Add a message (optional)</FormLabel>
                <Textarea {...register('notes')} variant='filled' />
              </FormControl>
              <Box p={3}>
                <Heading mb={1} size='sm'>
                  Refund Policy
                </Heading>
                <Text color='gray.400' fontSize='xs'>
                  Where applicable. Up to a single class fee will be gladly refunded on the basis of
                  any dissatisfaction with that class. Please provide in writing your request with
                  an explanation so that Circlework Training can improve our delivery in future.
                </Text>
              </Box>
              {(errorMessage || isError) && (
                <Text color='red.500' fontSize='sm'>
                  {errorMessage ?? 'There was an error processing your payment. Please try again.'}
                </Text>
              )}
            </ModalBody>
            <ModalFooter py={2}>
              <ButtonGroup>
                <Button onClick={onClose}>Cancel</Button>
                <Button
                  colorScheme='purple'
                  isLoading={isSubmitting || isLoading}
                  mb={4}
                  type='submit'
                >
                  Pay
                </Button>
              </ButtonGroup>
            </ModalFooter>
          </form>
        </ModalContent>
      </Modal>
    </>
  );
}

export default PayHere;
