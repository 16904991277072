import { Link } from '@chakra-ui/next-js';
import { Box, Flex, Heading, ListItem, Text, UnorderedList } from '@chakra-ui/react';
import { css } from '@emotion/react';
import { RichTextField, TitleField } from '@prismicio/client';
import * as prismicH from '@prismicio/helpers';
import { PrismicNextImage, PrismicNextImageProps } from '@prismicio/next';
import {
  PrismicRichText as BasePrismicRichText,
  PrismicLink,
  PrismicRichTextProps,
} from '@prismicio/react';
import { Fragment } from 'react';
import ReactPlayer from 'react-player';
import Balancer from 'react-wrap-balancer';

export function replaceNbSp(text: string) {
  return text.replace(/\u00a0/g, ' ');
}

export function richTextToString(richText?: RichTextField | TitleField) {
  if (!richText) return '';
  return replaceNbSp(prismicH.asText(richText));
}

export const linkResolver: prismicH.LinkResolverFunction = (doc) => {
  if (!doc || doc === null) return '#';

  switch (doc.type) {
    case 'home_page':
      return '/';
    case 'blog_post':
      return `/blog/${doc.uid}`;
    case 'video_post':
      return `/videos/${doc.uid}`;
    case 'event_post':
      return `/events/${doc.uid}`;
    default:
      return `/${doc.uid}`;
  }
};

const defaultComponents: PrismicRichTextProps['components'] = {
  heading1: ({ children, key }) => (
    <Heading key={key} as='h1' fontSize='4xl' mb={4} variant='h1'>
      <Balancer>{children}</Balancer>
    </Heading>
  ),
  heading2: ({ children, key }) => (
    <Heading key={key} as='h2' fontSize='3xl' mb={3} variant='h2'>
      <Balancer>{children}</Balancer>
    </Heading>
  ),
  heading3: ({ children, key }) => (
    <Heading key={key} as='h3' fontSize='2xl' mb={2} variant='h3'>
      <Balancer>{children}</Balancer>
    </Heading>
  ),
  heading4: ({ children, key }) => (
    <Heading key={key} as='h4' fontFamily='body' fontSize='x-large' mb={2} variant='subtitle'>
      <Balancer>{children}</Balancer>
    </Heading>
  ),
  heading5: ({ children, key }) => (
    <Heading key={key} as='h5' fontFamily='body' fontSize='larger' mb={2}>
      <Balancer>{children}</Balancer>
    </Heading>
  ),
  heading6: ({ children, key }) => (
    <Heading key={key} as='h6' fontFamily='body' fontSize='large' mb={2}>
      <Balancer>{children}</Balancer>
    </Heading>
  ),
  paragraph: ({ children, key }) => (
    <Text key={key} as='p' mb={4}>
      <Balancer>{children}</Balancer>
    </Text>
  ),
  preformatted: ({ children, key }) => (
    <Flex key={key} mb={4}>
      <Box bgColor='#D4CAC7' h='auto' mr={4} w='2' />
      <Text color='#706663' fontFamily='heading' fontStyle='italic' fontWeight='400' py={2}>
        {children}
      </Text>
    </Flex>
  ),
  list: ({ children, key }) => (
    <UnorderedList key={key} as='ul'>
      {children}
    </UnorderedList>
  ),
  listItem: ({ children, key }) => (
    <ListItem key={key} as='li' mb={4}>
      <Balancer>{children}</Balancer>
    </ListItem>
  ),
  image: ({ key, node }) => (
    <Flex
      key={key}
      alignItems='center'
      flexDirection='column'
      justifyContent='center'
      maxW='550px'
      mb='4'
      mx='auto'
    >
      <PrismicNextImage
        field={node as unknown as PrismicNextImageProps['field']}
        height={node.dimensions.height}
        width={node.dimensions.width}
      />
      {node.alt && (
        <Text
          as='figcaption'
          color='gray.500'
          fontFamily='body'
          fontSize='sm'
          fontStyle='italic'
          mt={2}
          px={4}
        >
          <Balancer>{node.alt}</Balancer>
        </Text>
      )}
    </Flex>
  ),
  embed: ({ key, node }) => (
    <Flex
      key={key}
      css={css`
        & > div {
          width: 100%;
          width: 100% !important;
          max-width: 550px !important;
          margin: 0 auto !important;
        }
        & > div > iframe {
          width: 100% !important;
          max-width: 550px !important;
          margin: 0 auto !important;
        }
      `}
      sx={{ justifyContent: 'center', alignItems: 'center', my: 4 }}
    >
      <ReactPlayer controls url={node.oembed.embed_url} width='100%' />
    </Flex>
  ),
  hyperlink: ({ children, key, node }) => (
    <PrismicLink
      key={key}
      externalComponent={(props) => (
        <Link color='blue.500' href={props.href ?? ''}>
          {props.children}
        </Link>
      )}
      field={node.data}
      internalComponent={(props) => (
        <Link color='blue.500' href={props.href ?? ''}>
          {props.children}
        </Link>
      )}
      linkResolver={linkResolver}
    >
      <Balancer>{children}</Balancer>
    </PrismicLink>
  ),
  span: ({ text, key }) => {
    const result: React.ReactNode[] = [];

    let i = 0;
    for (const line of text.split('\n')) {
      if (i > 0) {
        result.push(<br key={`${i}__break`} />);
      }
      result.push(<Fragment key={`${i}__line`}>{line}</Fragment>);
      i++;
    }

    return <Fragment key={key}>{result}</Fragment>;
  },
};

export const PrismicRichText = (props: PrismicRichTextProps) => {
  return (
    <BasePrismicRichText components={{ ...defaultComponents, ...props.components }} {...props} />
  );
};
