import { useCreateSubscriptionMutation } from '@/reducers/api';
import { useAppDispatch, useAppSelector } from '@/reducers/hooks';
import { closeMenu, openMenu } from '@/reducers/menu/menuSlice';

import {
  Button,
  ButtonProps,
  Flex,
  FormControl,
  FormLabel,
  Icon,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Stack,
  Text,
} from '@chakra-ui/react';
import { ChangeEvent, FormEvent, useState } from 'react';
import { FaFacebook, FaInstagram } from 'react-icons/fa';

function JoinNow(props: ButtonProps) {
  const [email, setEmail] = useState('');
  const [errorMessage, setErrorMessage] = useState<string | undefined>(undefined);
  const [createSubscription, { isError, isLoading, isSuccess }] = useCreateSubscriptionMutation();

  const menuState = useAppSelector((state) => state.menu.isOpen);
  const isOpen = menuState === 'join';
  const dispatch = useAppDispatch();
  const onClose = () => dispatch(closeMenu());

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    setEmail(e.target.value);
  };

  const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    try {
      const subscriptionResponse = await createSubscription({
        email,
      });
      if ('data' in subscriptionResponse) {
        if (subscriptionResponse.data.error) {
          setErrorMessage(subscriptionResponse.data.text);
        }
      }
    } catch (error) {
      if (error instanceof Error) setErrorMessage(error.message);
    }
  };

  return (
    <>
      <Button colorScheme='green' onClick={() => dispatch(openMenu('join'))} {...props}>
        Join now
      </Button>
      <Modal isOpen={isOpen} size='md' onClose={onClose}>
        <ModalOverlay />
        <ModalContent bg={`url('/white_wall_hash.webp')`} borderRadius={0} color='black'>
          <ModalCloseButton />
          <ModalHeader>Sign up for updates on future events</ModalHeader>
          <ModalBody>
            <Stack pb={4}>
              <Button
                as='a'
                colorScheme='facebook'
                href='https://www.facebook.com/circlework.training'
                leftIcon={<Icon as={FaFacebook} />}
                target='_blank'
              >
                Follow our Facebook
              </Button>
              <Button
                as='a'
                colorScheme='pink'
                href='https://www.instagram.com/circlework.training'
                leftIcon={<Icon as={FaInstagram} />}
                target='_blank'
              >
                Follow our Instagram
              </Button>
            </Stack>
            <form onSubmit={handleSubmit}>
              <FormControl mb={4}>
                <FormLabel>Your Email</FormLabel>
                <Input
                  isDisabled={isSuccess}
                  name='email'
                  type='email'
                  value={email}
                  variant='filled'
                  onChange={handleChange}
                />
              </FormControl>
              <Flex justifyContent='flex-end'>
                <Button colorScheme='green' isLoading={isLoading} mb={4} type='submit'>
                  Join mailing list
                </Button>
              </Flex>
              {(isError || errorMessage) && (
                <Text color='red.400'>{errorMessage ?? 'Something went wrong'}</Text>
              )}
              {isSuccess && <Text color='green.400'>Thank you for joining our mailing list!</Text>}
            </form>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
}

export default JoinNow;
